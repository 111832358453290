// DO NOT REMOVE THE "!": bundled builds breaks otherwise !!!
import maplibregl from "!maplibre-gl";

/**
 * Transforms a set of parameters into an URL-ready string
 * It also removes null/undefined values
 *
 * @param {object} params The parameters object
 * @return {string} The URL query part
 * @private
 */
function geocoderParamsToURLString(params) {
	let p = {};
	Object.entries(params)
		.filter(e => e[1] !== undefined && e[1] !== null)
		.forEach(e => p[e[0]] = e[1]);

	return new URLSearchParams(p).toString();
}

/**
 * Transforms Nominatim search result into a nice-to-display address.
 * @param {object} addr The Nominatim API "address" property
 * @returns {string} The clean-up string for display
 * @private
 */
function nominatimAddressToPlaceName(addr) {
	// API format @ https://nominatim.org/release-docs/develop/api/Output/#addressdetails
	if(!addr || typeof addr != "object") { return ""; }

	let res = "";

	// House n°-like
	if(addr.house_number) { res = addr.house_number; }
	else if(addr.house_name) { res = addr.house_name; }
	else {
		const potentialNames = [
			"emergency", "historic", "military", "natural", "landuse", "place", "railway", "man_made",
			"aerialway", "boundary", "amenity", "aeroway", "club", "craft", "leisure", "office",
			"mountain_pass", "shop", "tourism", "bridge", "tunnel", "waterway", "park"
		];
		for(let pn of potentialNames) {
			if(addr[pn]) {
				res = addr[pn];
				break;
			}
		}
	}

	// Street-like
	let street;
	if(addr.road && addr.road.length > 6) { street = addr.road; }
	else {
		const potentialNames = [
			// Hamlet-like
			"hamlet", "croft", "isolated_dwelling",
			// Zone Indus-like
			"farm", "farmyard", "industrial", "commercial", "retail", "city_block", "residential",
			// Quarter-like
			"neighbourhood", "allotments", "quarter"
		];
		for(let pn of potentialNames) {
			if(addr[pn]) {
				street = addr[pn];
				break;
			}
		}
	}

	if(street && res.length > 0) { res += (addr.house_number ? " " : ", ")+street; }
	else if(street) { res = street; }

	// City
	if(addr.village || addr.town || addr.city || addr.municipality) {
		if(res.length > 0) { res += ", "; }
		res += addr.village || addr.town || addr.city || addr.municipality;
	}

	return res;
}

/**
 * Nominatim (OSM) geocoder, ready to use for our Map
 * @private
 */
export function forwardGeocodingNominatim(config) {
	// Transform parameters into Nominatim format
	const params = {
		q: config.query,
		countrycodes: config.countries,
		limit: config.limit,
		viewbox: config.bbox,
	};

	return fetch(`https://nominatim.openstreetmap.org/search?${geocoderParamsToURLString(params)}&format=geojson&polygon_geojson=1&addressdetails=1`)
		.then(res => res.json())
		.then(res => {
			const finalRes = { features: [] };
			const listedNames = [];
			res.features.forEach(f => {
				const plname = nominatimAddressToPlaceName(f.properties.address) || f.properties.display_name;
				if(!listedNames.includes(plname)) {
					finalRes.features.push({
						place_type: ["place"],
						place_name: plname,
						bounds: new maplibregl.LngLatBounds(f.bbox),
					});
					listedNames.push(plname);
				}
			});
			return finalRes;
		});
}

export function reverseGeocodingNominatim(lat, lon) {
	return fetch(`https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lon}&zoom=18&format=jsonv2`)
		.then(res => res.json())
		.then(res => nominatimAddressToPlaceName(res?.address));
}

/**
 * Base adresse nationale (FR) geocoder, ready to use for our Map
 * @param {object} config Configuration sent by MapLibre GL Geocoder, following the geocoderApi format ( https://github.com/maplibre/maplibre-gl-geocoder/blob/main/API.md#setgeocoderapi )
 * @returns {object} GeoJSON Feature collection in Carmen GeoJSON format
 * @private
 */
export function forwardGeocodingBAN(config) {
	// Transform parameters into BAN format
	const params = { q: config.query, limit: config.limit };
	if(typeof config.proximity === "string") {
		const [lat, lon] = config.proximity.split(",").map(v => parseFloat(v.trim()));
		params.lat = lat;
		params.lon = lon;
	}

	const toPlaceName = p => [p.name, p.district, p.city].filter(v => v).join(", ");
	const placeTypeToZoom =  { "housenumber": 20, "street": 18, "locality": 15, "municipality": 12 };
	
	return fetch(`https://api-adresse.data.gouv.fr/search/?${geocoderParamsToURLString(params)}`)
		.then(res => res.json())
		.then(res => {
			res.features = res.features.map(f => ({
				place_type: ["place"],
				place_name: toPlaceName(f.properties),
				center: new maplibregl.LngLat(...f.geometry.coordinates),
				zoom: placeTypeToZoom[f.properties.type],
			}));
			return res;
		});
}