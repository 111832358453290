import { LitElement, css, nothing } from "lit";
import { html, unsafeStatic } from "lit/static-html.js";
import { fa } from "../../utils/widgets";
import { josmBboxParameters } from "../../utils/utils";
import { faSvg, textarea, titles } from "../styles";

import { faLink } from "@fortawesome/free-solid-svg-icons/faLink";
import { faCloudArrowDown } from "@fortawesome/free-solid-svg-icons/faCloudArrowDown";
import { faCopy } from "@fortawesome/free-solid-svg-icons/faCopy";
import { faPrint } from "@fortawesome/free-solid-svg-icons/faPrint";
import { faMap } from "@fortawesome/free-solid-svg-icons/faMap";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons/faCircleInfo";
import { faPen } from "@fortawesome/free-solid-svg-icons/faPen";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons/faLocationDot";
import { faSatelliteDish } from "@fortawesome/free-solid-svg-icons/faSatelliteDish";
import { faSquareRss } from "@fortawesome/free-solid-svg-icons/faSquareRss";

const JOSM_REMOTE_URL = "http://127.0.0.1:8111";
const ID_URL = "https://www.openstreetmap.org/edit?editor=id";

/**
 * Share Menu displays links for quick picture sharing.
 * @class Panoramax.components.menus.ShareMenu
 * @element pnx-share-menu
 * @extends [lit.LitElement](https://lit.dev/docs/api/LitElement/)
 * @example
 * ```html
 * <pnx-share-menu ._parent=${viewer} />
 * ```
 */
export default class ShareMenu extends LitElement {
	/** @private */
	static styles = [ faSvg, textarea, titles, css`
		.pnx-many-buttons {
			display: flex;
			flex-direction: row;
			width: 100%;
			gap: 5px;
			justify-content: space-between;
			align-items: center;
			flex-wrap: wrap;
			white-space: nowrap;
		}

		.pnx-many-buttons textarea {
			font-size: 0.8em;
			height: 50px;
		}

		.pnx-many-buttons pnx-button,
		.pnx-many-buttons pnx-copy-button,
		.pnx-many-buttons pnx-link-button {
			flex-basis: 100%;
			flex-grow: 2;
			flex-shrink: 2;
		}

		.pnx-many-buttons.pnx-edit-buttons pnx-button,
		.pnx-many-buttons.pnx-edit-buttons pnx-link-button {
			flex-basis: 30%;
			flex-grow: 1;
			flex-shrink: 1;
		}
	` ];

	/** @private */
	static properties = {
		_pic: {state: true},
		_baseUrl: {state: true},
		_josm: {state: true},
	};

	constructor() {
		super();
		this._josm = false;
		this._onUrlChange();
	}

	/** @private */
	connectedCallback() {
		super.connectedCallback();
		this._parent?.onceReady().then(() => {
			this._onUrlChange();
			this._parent.urlHandler.addEventListener("url-changed", this._onUrlChange.bind(this));
			
			this._parent.onceFirstPicLoaded().then(this._onPictureLoad.bind(this));
			this._parent.psv.addEventListener("picture-loaded", this._onPictureLoad.bind(this));
		});
	}

	/** @private */
	_onUrlChange() {
		this._baseUrl = window.location.href.replace(/\/$/, "");
	}

	/** @private */
	_onPictureLoad() {
		this._pic = this._parent?.psv?.getPictureMetadata();
	}

	/**
	 * Enable or disable JOSM live editing using [Remote](https://josm.openstreetmap.de/wiki/Help/RemoteControlCommands)
	 * @private
	 */
	_toggleJOSMLive() {
		this._josm = !this._josm;

		if(this._josm) {
			// Check if JOSM remote is enabled
			fetch(JOSM_REMOTE_URL+"/version")
				.then(() => {
					// First loading : download + zoom
					const p1 = josmBboxParameters(this._parent?.psv?.getPictureMetadata?.());
					if(p1) {
						const url = `${JOSM_REMOTE_URL}/load_and_zoom?${p1}`;
						fetch(url).catch(e => {
							console.warn(e);
							this._toggleJOSMLive();
						});
					}

					// Enable event listening
					this._josmListener = () => {
						const p2 = josmBboxParameters(this._parent?.psv?.getPictureMetadata?.());
						if(p2) {
							// Next loadings : just zoom
							//   This avoids desktop focus to go on JOSM instead of
							//   staying on web browser
							const url = `${JOSM_REMOTE_URL}/zoom?${p2}`;
							fetch(url).catch(e => {
								console.warn(e);
								this._toggleJOSMLive();
							});
						}
					};
					this._parent?.psv?.addEventListener?.("picture-loaded", this._josmListener);
					this._parent?.psv?.addEventListener?.("picture-loading", this._josmListener);
				})
				.catch(e => {
					console.warn(e);
					alert(this._parent?._t.pnx.error_josm);
					this._toggleJOSMLive();
				});
		}
		else {
			if(this._josmListener) {
				this._parent?.psv?.removeEventListener?.("picture-loading", this._josmListener);
				this._parent?.psv?.removeEventListener?.("picture-loaded", this._josmListener);
				delete this._josmListener;
			}
		}
	}

	/** @private */
	render() {
		const shareUrl = this._parent?.urlHandler?.nextShortLink(this._baseUrl) || this._baseUrl;
		const iframe = `<iframe src="${shareUrl}" style="border: none; width: 500px; height: 300px"></iframe>`;

		const idOpts = this._pic && {
			"map": `19/${this._pic.gps[1]}/${this._pic.gps[0]}`,
			"source": "Panoramax",
			"photo_overlay": "panoramax",
			"photo": `panoramax/${this._pic.id}`,
		};
		const idUrl = idOpts && `${ID_URL}#${new URLSearchParams(idOpts).toString()}`;

		return html`
			${this._pic && html`
				<p style="margin: 0 0 10px 0;">
					${this._pic?.caption?.license ? html`${unsafeStatic(this._parent?._t.pnx.legend_license.replace("{l}", this._pic.caption.license))}` : ""}
				</p>
			`}

			<h4 style="margin-top: 0">${fa(faLink)} ${this._parent?._t.pnx.share_links}</h4>
			<div class="pnx-many-buttons">
				${this._pic && html`
					<pnx-link-button
						download
						target="_blank"
						href=${this._pic?.panorama?.hdUrl}
					>${fa(faCloudArrowDown)} ${this._parent?._t.pnx.share_image}</pnx-link-button>
				`}
				${this._parent?.api?.getRSSURL() && html`
					<pnx-link-button
						target="_blank"
						href=${this._parent?.api.getRSSURL(this._parent?.map?.getBounds?.())}
						title=${this._parent?._t.pnx.share_rss_title}
					>
						${fa(faSquareRss)} ${this._parent?._t.pnx.share_rss}
					</pnx-link-button>
				`}
				<pnx-copy-button ._t=${this._parent?._t} text=${shareUrl}>
					${fa(faCopy)} ${this._parent?._t.pnx.share_page}
				</pnx-copy-button>
				${this._parent.isWidthSmall() ? nothing : html`<pnx-button @click=${window.print.bind(window)}>
					${fa(faPrint)} ${this._parent?._t.pnx.share_print}
				</pnx-button>`}
			</div>

			<h4>
				${fa(faMap)} ${this._parent?._t.pnx.share_embed}
				<pnx-link-button
					href="https://docs.panoramax.fr/web-viewer/03_URL_settings/"
					title="${this._parent?._t.pnx.share_embed_docs}"
					target="_blank"
					kind="outline">
					${fa(faCircleInfo, {styles: {padding: "0 3px"}})}
				</pnx-link-button>
			</h4>
			<div class="pnx-many-buttons">
				<textarea readonly>${iframe}</textarea>
				<pnx-copy-button ._t=${this._parent?._t} text=${iframe}></pnx-copy-button>
			</div>
			
			${this._pic && html`
				<h4>${fa(faPen)} ${this._parent?._t.pnx.edit_osm}</h4>
				<div class="pnx-many-buttons pnx-edit-buttons">
					<pnx-link-button href=${idUrl} target="_blank">
						${fa(faLocationDot)} ${this._parent?._t.pnx.id}
					</pnx-link-button>
					<pnx-button
						id="pnx-edit-josm"
						active=${this._josm ? "" : nothing}
						@click=${this._toggleJOSMLive}
						title="${this._parent?._t.pnx.josm_live}"
					>${fa(faSatelliteDish)} ${this._parent?._t.pnx.josm}</pnx-button>
				</div>
			`}`;
	}
}

customElements.define("pnx-share-menu", ShareMenu);
