import { LitElement, html } from "lit";
import { fa } from "../../utils/widgets";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import { faCopy } from "@fortawesome/free-solid-svg-icons/faCopy";
import { btn, faSvg } from "../styles";

/**
 * Copy Button element allows top copy in clipboard some text.
 * @class Panoramax.components.ui.CopyButton
 * @element pnx-copy-button
 * @extends [lit.LitElement](https://lit.dev/docs/api/LitElement/)
 * @example
 * ```html
 * <pnx-copy-button text="content to copy" ._t=${viewer._t}>
 *   Copy me !
 * </pnx-copy-button>
 * ```
 */
export default class CopyButton extends LitElement {
	/** @private */
	static styles = [faSvg, btn];

	/**
     * Component properties.
	 * @memberof Panoramax.components.ui.CopyButton#
     * @type {Object}
     * @property {string} [text] Text to copy in clipboard on click (use either this parameter or input, not both)
	 * @property {input} [input] ID of a HTML input field to copy content from in clipboard (use either this parameter or text, not both)
     */
	static properties = {
		text: {type: String},
		input: {type: String},
		_active: {state: true, type: Boolean},
	};

	constructor() {
		super();
		this.data = "";
		this.input = "";
		this._active = false;
		this.addEventListener("click", this._onClick);
	}

	/** @private */
	_onClick() {
		let textToCopy;
		if(this.input !== "") {
			const inputField = document.getElementById(this.input);
			textToCopy = inputField.innerText || inputField.value;
		}
		else if(this.text !== "") {
			textToCopy = this.text;
		}
		navigator.clipboard.writeText(textToCopy);
		this._active = true;
		setTimeout(() => this._active = false, 2000);
	}

	/** @private */
	render() {
		/* eslint-disable indent */
		return html`<button class="pnx-btn pnx-btn-full ${this._active ? "pnx-btn-active" : ""}" part="btn">
			${this._active ?
				html`${this._t?.pnx.copied || "Copied"} ${fa(faCheck)}` :
				html`<slot>${fa(faCopy)} ${this._t?.pnx.copy || "Copy"}</slot>`
			}
		</button>`;
	}
}

customElements.define("pnx-copy-button", CopyButton);
