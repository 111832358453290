import { LitElement, html, nothing } from "lit";
import { fa } from "../../../utils/widgets";
import { faLayerGroup } from "@fortawesome/free-solid-svg-icons/faLayerGroup";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons/faChevronDown";
import { faXmark } from "@fortawesome/free-solid-svg-icons/faXmark";
import { activeIcon } from "../../styles";

/**
 * Collapsible button showing off map filters menus.
 * @class Panoramax.components.ui.widgets.MapLayersButton
 * @element pnx-widget-maplayers
 * @extends [lit.LitElement](https://lit.dev/docs/api/LitElement/)
 * @example
 * ```html
 * <pnx-widget-maplayers _parent=${viewer} />
 * ```
 */
export default class MapLayersButton extends LitElement {
	/** @private */
	static styles = [activeIcon];

	/** @private */
	static properties = {
		_active: {state: true},
	};

	constructor() {
		super();
		this._active = false;
	}

	/** @private */
	connectedCallback() {
		super.connectedCallback();

		const nullThemes = [undefined, null, "", "default"];

		this._parent?.onceMapReady?.().then(() => {
			this._active = !nullThemes.includes(this._parent.map._mapFilters.theme);
			this._parent.map.on("filters-changed", e => {
				this._active = !nullThemes.includes(e.theme);
			});
		});
	}

	/** @private */
	render() {
		const isSmall = this._parent?.isWidthSmall();
		const resetIcon = fa(faXmark);
		resetIcon.addEventListener("click", e => {
			e.stopPropagation();
			const menu = this.shadowRoot.querySelector("pnx-map-layers-menu");
			menu.shadowRoot.querySelector("#pnx-map-theme").value = "default";
			menu._onThemeSelect({target: {value: "default"}});
			this.shadowRoot.querySelector("#pnx-widget-map-layers")._opened = false;
		});

		return html`
			<pnx-togglable-group
				id="pnx-widget-map-layers"
				class="pnx-print-hidden"
				._parent=${this._parent}
			>
				<pnx-button kind="flat" size="xl" slot="button">
					${fa(faLayerGroup)}
					${this._active ? html`<span class="pnx-active-icon"></span>` : nothing}
					${isSmall ? nothing : this._parent?._t.pnx.layers}
					${isSmall ? nothing : (this._active ? resetIcon : fa(faChevronDown))}
				</pnx-button>
				<pnx-map-layers-menu
					id="pnx-map-layers-menu"
					._parent=${this._parent}
				></pnx-map-layers-menu>
			</pnx-togglable-group>
		`;
	}
}

customElements.define("pnx-widget-maplayers", MapLayersButton);
