import { LitElement, html, css } from "lit";
import { fa } from "../../utils/widgets";
import { faStar } from "@fortawesome/free-solid-svg-icons/faStar";
import { faStar as farStar } from "@fortawesome/free-regular-svg-icons/faStar";
import { faSvg } from "../styles";

/**
 * Grade element displays a 5-star rating.
 * @class Panoramax.components.ui.Grade
 * @element pnx-grade
 * @extends [lit.LitElement](https://lit.dev/docs/api/LitElement/)
 * @example
 * ```html
 * <pnx-grade stars="5" .t=${viewer._t} />
 * ```
 */
export default class Grade extends LitElement {
	/** @private */
	static styles = [ faSvg, css`
		span { color: var(--orange); }
	`];

	/**
     * Component properties.
	 * @memberof Panoramax.components.ui.Grade#
     * @type {Object}
     * @property {number} [stars=0] 1 to 5 grade (or 0 for unknown value)
     */
	static properties = {
		stars: {type: Number},
	};

	constructor() {
		super();
		this.stars = 0;
	}

	/** @private */
	render() {
		const nbStars = this.stars || 0;
		const fastars = [];
		for(let i=1; i <= nbStars; i++) {
			fastars.push(fa(faStar));
		}
		for(let i=nbStars+1; i <= 5; i++) {
			fastars.push(fa(farStar));
		}
		return html`<span>
			${fastars}
		</span> (${this.stars ? this.stars+"/5" : this._t?.pnx.metadata_quality_missing || "?"})`;
	}
}

customElements.define("pnx-grade", Grade);
