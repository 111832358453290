import {LitElement, html, nothing, css} from "lit";
import {fa} from "../../utils/widgets";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons/faCircleInfo";
import { placeholder } from "../styles";
import { reverseGeocodingNominatim } from "../../utils/geocoder";

/**
 * Picture legend shows info about picture author, capture date, address, and access to metadata popup.
 * @class Panoramax.components.menus.PictureLegend
 * @element pnx-picture-legend
 * @extends [lit.LitElement](https://lit.dev/docs/api/LitElement/)
 * @example
 * ```html
 * <pnx-picture-legend ._parent=${viewer} />
 * ```
 */
export default class PictureLegend extends LitElement {
	/** @private */
	static styles = [placeholder, css`
		.addr {
			line-height: 1.2em;
			font-size: 1em;
			margin-bottom: 2px;
		}

		.addr span {
			display: inline-block;
			height: 100%;
			width: 100%;
		}

		.context {
			font-size: 0.9em;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		pnx-button { float: right; vertical-align: sub; }
	`];

	/** @private */
	static properties = {
		_caption: { state: true },
		_addr: { state: true },
	};

	/** @private */
	connectedCallback() {
		super.connectedCallback();

		this._prevSearches = {};

		this._parent.onceReady().then(() => {
			this._onPicChange(this._parent.psv.getPictureMetadata());
			this._parent.psv.addEventListener("picture-loaded", () => {
				this._onPicChange(this._parent.psv.getPictureMetadata());
			});
		});
	}

	/** @private */
	_onPicChange(picMeta) {
		clearTimeout(this._addrTimer1);
		this._caption = picMeta?.caption;
		
		if(picMeta) {
			const coordsHash = `${picMeta.gps[0]}/${picMeta.gps[1]}`;
			if(this._prevSearches[coordsHash]) {
				this._addr = this._prevSearches[coordsHash];
			}
			else {
				this._addrTimer2 = setTimeout(() => this._addr = "", 250);
				this._addrTimer1 = setTimeout(() => {
					reverseGeocodingNominatim(picMeta.gps[1], picMeta.gps[0])
						.then(addr => {
							clearTimeout(this._addrTimer2);
							this._addr = addr;
							this._prevSearches[coordsHash] = addr;
						});
				}, 750);
			}
		}
		else {
			this._addr = "";
		}
	}

	/** @private */
	render() {
		if(!this._caption) { return nothing; }

		return html`
			<div class="addr">
				${this._addr?.length > 0 ? this._addr : html`<span class="pnx-placeholder-loading">&nbsp;</span>`}
			</div>
			<div class="context">
				${this._caption.producer ? this._caption.producer : nothing}
				${this._caption.producer && this._caption.date ? html`-` : nothing}
				${this._caption.date ? this._caption.date.toLocaleDateString(undefined, { year: "numeric", month: "long", day: "numeric" }) : nothing}
				<pnx-button
					kind="outline"
					title=${this._parent?._t.pnx.legend_title}
					@click=${() => this._parent?._showPictureMetadata()}
				>${fa(faCircleInfo, { styles: { height: "12px" }})}</pnx-button>
			</div>
		`;
	}
}

customElements.define("pnx-picture-legend", PictureLegend);
