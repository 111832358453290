import { LitElement, html, css } from "lit";
import { QUALITYSCORE_VALUES } from "../../utils/utils";

/**
 * Quality Score element displays the picture quality grade.
 * @class Panoramax.components.ui.QualityScore
 * @element pnx-quality-score
 * @extends [lit.LitElement](https://lit.dev/docs/api/LitElement/)
 * @fires Panoramax.components.ui.QualityScore#change
 * @example
 * ```html
 * <!-- Read-only -->
 * <pnx-quality-score grade="5" ._t=${viewer._t} />
 * 
 * <!-- Input -->
 * <pnx-quality-score grade="5" input="pnx-qs-input" @change=${() => alert('changed')} ._t=${viewer._t} />
 * ```
 */
export default class QualityScore extends LitElement {
	/** @private */
	static styles = css`
		:host {
			gap: 0px;
			justify-content: center;
			height: 42px;
			display: inline-flex;
			align-items: center;
		}
	
		/* Single letter */
		span, label {
			font-size: 18px;
			width: 25px;
			height: 30px;
			line-height: 28px;
			display: inline-block;
			border: 1px solid white;
			text-align: center;
			background-color: gray;
			color: rgba(255,255,255,0.9);
			font-family: var(--font-family);
			font-weight: 800;
			vertical-align: middle;
		}

		/* Rounded corners for first/last */
		span:first-of-type, label:first-of-type {
			border-top-left-radius: 8px !important;
			border-bottom-left-radius: 8px !important;
		}

		span:last-of-type, label:last-of-type {
			border-top-right-radius: 8px !important;
			border-bottom-right-radius: 8px !important;
		}

		/* Selected letter */
		.selected, input[type="checkbox"]:checked + label {
			width: 30px;
			height: 42px;
			line-height: 41px;
			border-radius: 8px;
			font-size: 27px;
			color: white;
			border: 2px solid white;
		}

		/* Clickable letter for input */
		label { cursor: pointer; }

		label:hover {
			width: 28px;
			height: 35px;
			line-height: 34px;
			border-radius: 3px;
			font-size: 22px;
			color: white;
			border: 2px solid white;
		}

		input[type="checkbox"] { display: none; }
	`;

	/**
	 * Component properties.
	 * @memberof Panoramax.components.ui.QualityScore#
	 * @type {Object}
	 * @property {number|string} [grade=0] The grade (5=A, 1=E, 0=none selected), or a list of grades
 	 * @property {string} [input=false] If set, score is an input and value is used as ID prefix
	 */
	static properties = {
		grade: {type: String},
		input: {type: String},
	};

	constructor() {
		super();
		this.grade = 0;
		this.input = false;
	}

	/** @private */
	_onInput() {
		// List all checked boxes
		const newgrade = [];
		for(let i of this.renderRoot.querySelectorAll("input")) {
			if(i.checked) { newgrade.push(i.value); }
		}
		this.grade = newgrade.join(",");

		/**
		 * Event for grade change
		 * @event Panoramax.components.ui.QualityScore#change
		 * @type {CustomEvent}
		 */
		this.dispatchEvent(new CustomEvent("change", {bubbles: true, composed: true}));
	}

	/** @private */
	render() {
		const grades = `${this.grade}`.split(",").map(v => parseInt(v)).filter(v => !isNaN(v));
		if(this.input) {
			return QUALITYSCORE_VALUES.map((pv, index) => {
				return html`
				<input
					id="${this.input}-${pv.label}"
					type="checkbox"
					name="qualityscore"
					value="${5-index}"
					@change="${this._onInput}"
					.checked=${grades.includes(5-index)}
				/>
				<label
					for="${this.input}-${pv.label}"
					title="${this._t?.pnx.filter_qualityscore_help}"
					style="background-color: ${pv.color}"
				>
					${pv.label}
				</label>
			`;});
		}
		else {
			return QUALITYSCORE_VALUES.map((pv, index) => html`
				<span class="${grades.includes(5-index) ? "selected" : ""}" style="background-color: ${pv.color}">
					${pv.label}
				</span>
			`);
		}
	}
}

customElements.define("pnx-quality-score", QualityScore);
