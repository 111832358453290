import { LitElement, html, css } from "lit";
import { panel } from "../styles";
import { listenForMenuClosure } from "../../utils/widgets";

/**
 * Togglable Group element allows to make a menu appear/disappear based on button click.
 * @class Panoramax.components.ui.TogglableGroup
 * @element pnx-togglable-group
 * @slot `button` The button on which click leads to open/close menu
 * @slot `default` The menu to make shown/hidden on button click
 * @extends [lit.LitElement](https://lit.dev/docs/api/LitElement/)
 * @example
 * ```html
 * <pnx-togglable-group padded="false" ._parent=${viewer}>
 *   <pnx-button slot="button">
 *     Open menu
 *   </pnx-button>
 *   <div>Menu content</div>
 * </pnx-togglable-group>
 * ```
 */
export default class TogglableGroup extends LitElement {
	/** @private */
	static styles = [ panel, css`
		div.container {
			position: relative;
			height: 100%;
		}

		.pnx-panel {
			z-index: 130;
		}
	` ];

	/**
	 * Component properties.
	 * @memberof Panoramax.components.ui.TogglableGroup#
	 * @type {Object}
	 * @property {string} [padded] If set, adds a 15px padding to the menu panel.
	 */
	static properties = {
		_opened: {state: true},
		padded: {type: String},
	};

	constructor() {
		super();
		this._opened = false;
	}

	/** @private */
	open() {
		this._opened = true;
		this._parent?.dispatchEvent(new CustomEvent("menu-opened", { detail: { menu: this }}));
		this.adjustMenuPosition(this._btn);
		if(this._chevron) { this._chevron.style.rotate = "180deg"; }
	}

	/** @private */
	close() {
		this._opened = false;
		if(this._chevron) { this._chevron.style.rotate = ""; }
	}

	/** @private */
	connectedCallback() {
		super.connectedCallback();
		listenForMenuClosure(this, this.close.bind(this));
	}

	/** @private */
	handleButtonSlotChange(e) {
		this._btn = e.target.assignedNodes().pop();
		if(this._btn) {
			// Chevron
			this._chevron = this._btn.querySelector("svg.fa-chevron-down");
			if(this._chevron) {
				this._chevron.style.transition = "rotate 0.2s";
				this._chevron.style.rotate = this._opened ? "180deg" : "";
			}

			this._btn.addEventListener("click", () => {
				if(this._opened) { this.close(); }
				else { this.open(); }
			});
		}
	}

	/** @private */
	adjustMenuPosition(btn) {
		const smallWidth = window.innerWidth < 576;
		const menu = this.shadowRoot.querySelector(".pnx-panel");
		const btnRect = btn.getBoundingClientRect();
		let menuRect = menu.getBoundingClientRect();

		const overflowHeight = menuRect.height > window.innerHeight * 0.8;
		if(overflowHeight) {
			menu.style.height = `${window.innerHeight * 0.8}px`;
			menu.style.overflowY = "scroll";
			menuRect = menu.getBoundingClientRect();
		}
		else {
			// Try to remove height limiter
			menu.style.height = "unset";
			menu.style.overflowY = "unset";
			menuRect = menu.getBoundingClientRect();

			// If height is too high, put back limitation
			if(menuRect.height > window.innerHeight * 0.8) {
				menu.style.height = `${window.innerHeight * 0.8}px`;
				menu.style.overflowY = "scroll";
				menuRect = menu.getBoundingClientRect();
			}
		}

		const overflowRight = btnRect.left + menuRect.width > window.innerWidth;
		const overflowBottom = btnRect.bottom + menuRect.height > window.innerHeight;
		const overflowTop = btnRect.top - menuRect.height < 0;

		if(overflowRight && overflowBottom && overflowTop) {
			menu.style.top = smallWidth ? "0px" : `${btnRect.height + 5}px`;
			menu.style.bottom = null;
			menu.style.right = `${btnRect.width + 5}px`;
			menu.style.left = null;
		}
		else if(overflowRight && overflowBottom) {
			menu.style.top = null;
			menu.style.bottom = "0px";
			menu.style.right = `${btnRect.width + 5}px`;
			menu.style.left = null;
		}
		else if(overflowRight) {
			menu.style.top = smallWidth ? "0px" : `${btnRect.height + 5}px`;
			menu.style.bottom = null;
			menu.style.right = smallWidth ? `${btnRect.width + 5}px` : "0px";
			menu.style.left = null;
		}
		else if(overflowBottom && !overflowTop) {
			menu.style.top = null;
			menu.style.bottom = "0px";
			menu.style.left = `${btnRect.width + 5}px`;
		}
		else {
			menu.style.top = `${btnRect.height + 5}px`;
			menu.style.right = null;
			menu.style.left = "0px";
			menu.style.bottom = null;
		}
	}

	/** @private */
	render() {
		return html`<div class="container">
			<slot name="button" @slotchange=${this.handleButtonSlotChange}></slot>
			<div class="pnx-panel ${this._opened ? "" : "pnx-hidden"} ${this.padded == "false" ? "" : "pnx-padded"}">
				<slot></slot>
			</div>
		</div>`;
	}
}

customElements.define("pnx-togglable-group", TogglableGroup);
