import { LitElement, html, css } from "lit";
import { faSvg } from "../styles";
import { faRocket } from "@fortawesome/free-solid-svg-icons/faRocket";
import { faLightbulb } from "@fortawesome/free-solid-svg-icons/faLightbulb";
import { faPersonBiking } from "@fortawesome/free-solid-svg-icons/faPersonBiking";
import { PIC_MAX_STAY_DURATION } from "../ui/Photo";
import { fa } from "../../utils/widgets";

/**
 * Player Options menu displays player speed and contrast settings.
 * @class Panoramax.components.menus.PlayerOptions
 * @element pnx-player-options
 * @extends [lit.LitElement](https://lit.dev/docs/api/LitElement/)
 * @example
 * ```html
 * <pnx-player-options ._parent=${viewer} />
 * ```
 */
export default class PlayerOptions extends LitElement {
	/** @private */
	static styles = [ faSvg, css`
		:host {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			gap: 15px;
			padding: 5px 15px;
		}

		.pnx-input-range {
			display: flex;
			justify-content: space-between;
			gap: 10px;
			align-items: center;
			width: 100%;
		}
	` ];

	/** @private */
	connectedCallback() {
		super.connectedCallback();
		this._parent?.psv?.addEventListener("transition-duration-changed", e => {
			this.renderRoot.querySelector("#pnx-player-speed").value = PIC_MAX_STAY_DURATION - e.detail.value;
		});
	}

	/** @private */
	_onSpeedChange(e) {
		const newSpeed = PIC_MAX_STAY_DURATION - e.target.value;
		this._parent?.psv?.setTransitionDuration(newSpeed);
	}

	/** @private */
	_onContrastClick() {
		const btn = this.renderRoot.querySelector("#pnx-player-contrast");
		if(btn.getAttribute("active") == "true") {
			btn.removeAttribute("active");
			this._parent?.psv?.setHigherContrast(false);
		}
		else {
			btn.setAttribute("active", "true");
			this._parent?.psv?.setHigherContrast(true);
		}
	}

	/** @private */
	render() {
		return html`
			<div class="pnx-input-range" title="${this._parent?._t.pnx.sequence_speed}">
				${fa(faPersonBiking)}
				<input
					id="pnx-player-speed"
					type="range" name="speed"
					min="0" max="${PIC_MAX_STAY_DURATION - 100}"
					value="${PIC_MAX_STAY_DURATION - this._parent?.psv.getTransitionDuration()}"
					title="${this._parent?._t.pnx.sequence_speed}"
					style="width: 100%;"
					@change=${this._onSpeedChange}
				/>
				${fa(faRocket)}
			</div>
			<pnx-button
				id="pnx-player-contrast"
				title="${this._parent?._t.pnx.contrast}"
				kind="outline"
				@click=${this._onContrastClick}
			>
				${fa(faLightbulb)}
			</pnx-button>
		`;
	}
}

customElements.define("pnx-player-options", PlayerOptions);
