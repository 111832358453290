import { LitElement, css, html } from "lit";

/**
 * Progress bar with nice styling.
 * @class Panoramax.components.ui.ProgressBar
 * @element pnx-progress-bar
 * @extends [lit.LitElement](https://lit.dev/docs/api/LitElement/)
 * @example
 * ```html
 * <pnx-loader value="42" ._t=${viewer._t} />
 * ```
 */
export default class ProgressBar extends LitElement {
	/** @private */
	static styles = css`
		progress, progress::-webkit-progress-bar {
			max-width: 80%;
			width: 300px;
			height: 16px;
			appearance: none;
			-webkit-appearance: none;
			border: 1px solid var(--grey-semi-dark);
			background-color: var(--grey);
			border-radius: 8px;
			overflow: hidden;
			padding: 3px;
			box-sizing: border-box;
		}

		progress::-webkit-progress-value,
		progress::-moz-progress-bar {
			background-color: var(--blue);
			border-radius: 5px;
			box-sizing: border-box;
		}

		progress[value=""]::-webkit-progress-value,
		progress[value=""]::-moz-progress-bar {
			animation: indeterminateAnimation 1.5s infinite linear;
		}

		@keyframes indeterminateAnimation {
			0%   { margin-left: 0%; width: 2%; }
			15%  { margin-left: 0%; width: 20%; }
			50%  { margin-left: 40%; width: 20%; }
			85%  { margin-left: 80%; width: 20%; }
			100% { margin-left: 98%; width: 2%; }
		}
	`;

	/**
	 * Component properties.
	 * @memberof Panoramax.components.ui.ProgressBar#
	 * @type {Object}
	 * @property {number} [value] Progress bar percentage (0-100) or empty for indeterminate state
	 */
	static properties = {
		value: {type: Number},
	};

	/** @private */
	render() {
		return html`
			<progress
				value=${this.value >= 0 ? this.value : ""}
				max="100"
				title=${this._t?.map.loading}
			></progress>
		`;
	}
}

customElements.define("pnx-progress-bar", ProgressBar);
