import { LitElement, html } from "lit";
import { PSV_DEFAULT_ZOOM, PSV_ANIM_DURATION } from "../Photo";
import { PSV_ZOOM_DELTA } from "../../core/Viewer";
import { fa } from "../../../utils/widgets";
import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import { faMinus } from "@fortawesome/free-solid-svg-icons/faMinus";
import "../ButtonGroup.css";

/**
 * Widget for +/- zoom on PSV or Map.
 * @class Panoramax.components.ui.widgets.Zoom
 * @element pnx-widget-zoom
 * @extends [lit.LitElement](https://lit.dev/docs/api/LitElement/)
 * @example
 * ```html
 * <pnx-widget-zoom _parent=${viewer} />
 * ```
 */
export default class Zoom extends LitElement {
	/** @private */
	static properties = {
		_lastWantedZoom: {state: true},
	};

	/** @private */
	createRenderRoot() {
		return this;
	}

	/** @private */
	connectedCallback() {
		super.connectedCallback();
		this._parent?.oncePSVReady?.().then(() => this._lastWantedZoom = this._parent?.psv?.getZoomLevel() || PSV_DEFAULT_ZOOM);
	}

	/** @private */
	_onClick(e, zoomIn) {
		if(this._parent.map && this._parent.isMapWide()) {
			if(zoomIn) { this._parent.map.zoomIn({}, {originalEvent: e}); }
			else { this._parent.map.zoomOut({}, {originalEvent: e}); }
		}
		else {
			if(this._parent.lastPsvAnim) { this._parent.lastPsvAnim.cancel(); }
			const goToZoom = zoomIn ?
				Math.min(100, this._lastWantedZoom + PSV_ZOOM_DELTA)
				: Math.max(0, this._lastWantedZoom - PSV_ZOOM_DELTA);
			this._parent.lastPsvAnim = this._parent.psv.animate({
				speed: PSV_ANIM_DURATION,
				zoom: goToZoom
			});
			this._lastWantedZoom = goToZoom;
		}
	}

	/** @private */
	render() {
		return html`
			<pnx-button-group
				id="pnx-widget-zoom"
				dir="column"
				size="xl"
				class="pnx-mobile-hidden pnx-print-hidden"
			>
				<pnx-button
					id="pnx-zoom-in"
					kind="superflat"
					title=${this._parent?._t.pnx.zoomIn}
					@click=${e => this._onClick(e, true)}
				>${fa(faPlus)}</pnx-button>

				<pnx-button
					id="pnx-zoom-out"
					kind="superflat"
					title=${this._parent?._t.pnx.zoomOut}
					@click=${e => this._onClick(e, false)}
				>${fa(faMinus)}</pnx-button>
			</pnx-button-group>
		`;
	}
}

customElements.define("pnx-widget-zoom", Zoom);
