import { LitElement, html, css } from "lit";
import { fa } from "../../utils/widgets";
import { panel, faSvg } from "../styles";
import { faXmark } from "@fortawesome/free-solid-svg-icons/faXmark";

/**
 * Popup Element displays a full overlay container.
 * @class Panoramax.components.ui.Popup
 * @element pnx-popup
 * @extends [lit.LitElement](https://lit.dev/docs/api/LitElement/)
 * @fires Panoramax.components.ui.Popup#open
 * @fires Panoramax.components.ui.Popup#close
 * @example
 * ```html
 * <pnx-popup @close=${() => alert("closed")}>
 *    <h3>Popup content</h3>
 * </pnx-popup>
 * ```
 */
export default class Popup extends LitElement {
	/** @private */
	static styles = [ panel, faSvg, css`
		/* Backdrop */
		:host {
			display: flex;
			align-items: center;
			justify-content: center;
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			bottom: 0;
			opacity: 0;
			visibility: hidden;
			pointer-events: none;
			transition: all 0.2s;
			background: rgba(0, 0, 0, 0.85);
		}
		:host(*[visible]) {
			opacity: 1;
			visibility: visible;
			pointer-events: unset;
			z-index: 200;
		}

		/* Content backgroud */
		.pnx-panel {
			box-sizing: border-box;
			position: relative;
			width: 90%;
			max-height: 90%;
			max-width: 550px;
			overflow-y: auto;
		}

		.btn-close {
			position: absolute;
			top: 15px;
			right: 15px;
		}
	`];

	/**
	 * Component properties.
	 * @memberof Panoramax.components.ui.Popup#
	 * @type {Object}
	 * @property {boolean} [visible=true] Is the popup visible to user ?
	 */
	static properties = {
		visible: {type: Boolean, reflect: true},
	};

	constructor() {
		super();
		this.visible = false;
	}

	/** @private */
	connectedCallback() {
		super.connectedCallback();

		// Backdrop closing
		this.addEventListener("click", e => {
			if(e.target === this) { this.close(); }
		});

		// Esc closing
		document.addEventListener("keyup", e => {
			if(e.key === "Escape" && this.visible) {
				this.close();
			}
		});
	}

	/** @private */
	attributeChangedCallback(name, _old, value) {
		super.attributeChangedCallback(name, _old, value);

		if(name == "visible") {
			if(value === "" || value === true) {
				/**
				 * Event for popup opening
				 * @event Panoramax.components.ui.Popup#open
				 * @type {Event}
				 */
				this.dispatchEvent(new Event("open"));
			}
		}
	}

	/**
	 * Close popup
	 * @memberof Panoramax.components.ui.Popup#
	 */
	close() {
		this.visible = false;

		/**
		 * Event for popup closing
		 * @event Panoramax.components.ui.Popup#close
		 * @type {Event}
		 */
		this.dispatchEvent(new Event("close"));
	}

	/** @private */
	render() {
		return html`
			<div class="pnx-panel pnx-padded">
				<pnx-button
					class="btn-close"
					kind="flat"
					size="l"
					@click=${this.close.bind(this)}
				>
					${fa(faXmark)}
				</pnx-button>

				<slot></slot>
			</div>
		`;
	}
}

customElements.define("pnx-popup", Popup);
