import { LitElement, html, nothing } from "lit";
import { fa } from "../../utils/widgets";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons/faCircleInfo";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons/faTriangleExclamation";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons/faLocationDot";
import { faMedal } from "@fortawesome/free-solid-svg-icons/faMedal";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons/faInfoCircle";
import { faCamera } from "@fortawesome/free-solid-svg-icons/faCamera";
import { faGear } from "@fortawesome/free-solid-svg-icons/faGear";
import { titles, tables, expandable } from "../styles";
import { createTable, createLinkCell, createWebComp } from "../../utils/widgets";
import { getGPSPrecision } from "../../utils/picture";
import {
	getGrade, QUALITYSCORE_GPS_VALUES, QUALITYSCORE_RES_360_VALUES,
	QUALITYSCORE_RES_FLAT_VALUES, QUALITYSCORE_POND_GPS, QUALITYSCORE_POND_RES
} from "../../utils/utils";

/**
 * Picture metadata displays detailed info about a single picture (ID, capture context, EXIF attributes...).
 * @class Panoramax.components.menus.PictureMetadata
 * @element pnx-picture-metadata
 * @extends [lit.LitElement](https://lit.dev/docs/api/LitElement/)
 * @example
 * ```html
 * <pnx-picture-metadata ._parent=${viewer} />
 * ```
 */
export default class PictureMetadata extends LitElement {
	/** @private */
	static styles = [ titles, tables, expandable ];

	/** @private */
	static properties = {
		_meta: {state: true},
	};

	/** @private */
	connectedCallback() {
		super.connectedCallback();

		this._meta = this._parent?.psv?.getPictureMetadata();
		this._parent?.psv?.addEventListener("picture-loaded", () => {
			this._meta = this._parent?.psv?.getPictureMetadata();
		});
	}

	/** @private */
	render() {
		/* eslint-disable indent */
		if(!this._meta) { return nothing; }

		// General metadata
		const generalData = [
			{
				section: this._parent?._t.pnx.metadata_general_picid,
				classes: ["pnx-td-with-id"],
				values: createLinkCell(
					this._meta.id,
					this._parent.api.getPictureMetadataUrl(this._meta.id, this._meta?.sequence?.id),
					this._parent?._t.pnx.metadata_general_picid_link,
					this._parent?._t
				)
			},
			{
				section: this._parent?._t.pnx.metadata_general_seqid,
				classes: ["pnx-td-with-id"],
				values: createLinkCell(
					this._meta?.sequence?.id,
					this._parent.api.getSequenceMetadataUrl(this._meta?.sequence?.id),
					this._parent?._t.pnx.metadata_general_seqid_link,
					this._parent?._t
				)
			},
			{ section: this._parent?._t.pnx.metadata_general_author, value: this._meta?.caption?.producer },
			{ section: this._parent?._t.pnx.metadata_general_license, value: this._meta?.caption?.license },
			{
				section: this._parent?._t.pnx.metadata_general_date,
				value: this._meta?.caption?.date?.toLocaleDateString(undefined, {
					year: "numeric", month: "long", day: "numeric",
					hour: "numeric", minute: "numeric", second: "numeric",
					fractionalSecondDigits: 3, timeZoneName: "short"
				})
			},
		];

		// Camera details
		const focal = this._meta?.properties?.["pers:interior_orientation"]?.focal_length ? `${this._meta?.properties?.["pers:interior_orientation"]?.focal_length} mm` : "❓";
		let resmp = this._meta?.properties?.["pers:interior_orientation"]?.["sensor_array_dimensions"];
		if(resmp) { resmp = `${resmp[0]} x ${resmp[1]} px (${Math.floor(resmp[0] * resmp[1] / 1000000)} Mpx)`;}
		let pictype = this._parent?._t.pnx.picture_flat;
		let picFov = this._meta?.properties?.["pers:interior_orientation"]?.["field_of_view"]; // Use raw value instead of horizontalFov to avoid default showing up
		if(picFov !== null && picFov !== undefined) {
			if(picFov === 360) { pictype = this._parent?._t.pnx.picture_360; }
			else { pictype += ` (${picFov}°)`; }
		}

		const cameraData = [
			{ section: this._parent?._t.pnx.metadata_camera_make, value: this._meta?.properties?.["pers:interior_orientation"]?.camera_manufacturer || "❓" },
			{ section: this._parent?._t.pnx.metadata_camera_model, value: this._meta?.properties?.["pers:interior_orientation"]?.camera_model || "❓" },
			{ section: this._parent?._t.pnx.metadata_camera_type, value: pictype },
			{ section: this._parent?._t.pnx.metadata_camera_resolution, value: resmp || "❓" },
			{ section: this._parent?._t.pnx.metadata_camera_focal_length, value: focal },
		];

		// Location details
		const orientation = this._meta?.properties?.["view:azimuth"] !== undefined ? `${this._meta.properties["view:azimuth"]}°` : "❓";
		const gpsPrecisionLabel = getGPSPrecision(this._meta);
		const locationData = [
			{ section: this._parent?._t.pnx.metadata_location_longitude, value: this._meta.gps[0] },
			{ section: this._parent?._t.pnx.metadata_location_latitude, value: this._meta.gps[1] },
			{ section: this._parent?._t.pnx.metadata_location_orientation, value: orientation },
			{ section: this._parent?._t.pnx.metadata_location_precision, value: gpsPrecisionLabel },
		];

		// Picture quality level
		const hasQualityScore = this._parent?.map?._hasQualityScore?.();
		let qualityData;
		if(hasQualityScore) {
			const gpsGrade = getGrade(QUALITYSCORE_GPS_VALUES, this._meta?.properties?.["quality:horizontal_accuracy"]);
			const resGrade = getGrade(
				this._meta?.horizontalFov === 360 ? QUALITYSCORE_RES_360_VALUES : QUALITYSCORE_RES_FLAT_VALUES,
				this._meta?.properties?.["panoramax:horizontal_pixel_density"]
			);

			// Note: score is also calculated in utils/map code
			const generalGrade = Math.round((resGrade || 1) * QUALITYSCORE_POND_RES + (gpsGrade || 1) * QUALITYSCORE_POND_GPS);

			qualityData = [
				{ section: this._parent?._t.pnx.metadata_quality_score, value: createWebComp("pnx-quality-score", { grade: generalGrade }) },
				{ section: this._parent?._t.pnx.metadata_quality_gps_score, value: createWebComp("pnx-grade", { stars: gpsGrade, _t: this._parent?._t }) },
				{ section: this._parent?._t.pnx.metadata_quality_resolution_score, value: createWebComp("pnx-grade", { stars: resGrade, _t: this._parent?._t }) },
			];
		}

		return html`
			<h4>${fa(faCircleInfo)} ${this._parent?._t.pnx.metadata}</h4>

			${this._parent.api._endpoints.report ? 
				html`
					<pnx-button ._t=${this._parent?._t} @click=${() => this._parent._showReportForm()}>
						${fa(faTriangleExclamation)} ${this._parent?._t.pnx.report}
					</pnx-button>
				` :
				nothing
			}

			${createTable("pnx-table-light", generalData)}

			<h4>${fa(faCamera)} ${this._parent?._t.pnx.metadata_camera}</h4>
			${createTable("pnx-table-light", cameraData)}

			<h4>${fa(faLocationDot)} ${this._parent?._t.pnx.metadata_location}</h4>
			${createTable("pnx-table-light", locationData)}

			${hasQualityScore ?
				html`
					<h4 style="margin-bottom: 5px">
						${fa(faMedal)} ${this._parent?._t.pnx.metadata_quality}
						<pnx-button
							title="${this._parent?._t.pnx.metadata_quality_help}"
							kind="outline"
							@click=${() => this._parent?._showQualityScoreDoc()}
						>
							${fa(faInfoCircle)}
						</pnx-button>
					</h4>
					${createTable("pnx-table-light", qualityData)}
				` :
				nothing
			}

			${this._meta.properties?.exif ?
				html`
					<details>
						<summary>${fa(faGear)} ${this._parent?._t.pnx.metadata_exif}</summary>
						${createTable("", Object.entries(this._meta.properties.exif)
							.sort()
							.map(([key, value]) => ({ section: key, value: value })
						))}
					</details>
				` :
				nothing
			}
		`;
	}
}

customElements.define("pnx-picture-metadata", PictureMetadata);
