import { LitElement, html } from "lit";
import { faSvg, titles } from "../styles";
import { fa } from "../../utils/widgets";
import { faMedal } from "@fortawesome/free-solid-svg-icons/faMedal";

/**
 * Quality Score Doc displays a synthetic summary of how Quality Score is computed.
 * @class Panoramax.components.menus.QualityScoreDoc
 * @element pnx-quality-score-doc
 * @extends [lit.LitElement](https://lit.dev/docs/api/LitElement/)
 * @example
 * ```html
 * <pnx-quality-score-doc ._t=${viewer._t} />
 * ```
 */
export default class QualityScoreDoc extends LitElement {
	static styles = [ titles, faSvg ];

	render() {
		return html`
			<h4>${fa(faMedal)} ${this._t?.pnx.qualityscore_title}</h4>
			<p>${this._t?.pnx.qualityscore_doc_1}</p>
			<p>${this._t?.pnx.qualityscore_doc_2}</p>
			<pnx-quality-score grade="5"></pnx-quality-score>
			<p>${this._t?.pnx.qualityscore_doc_3}</p>
			<pnx-link-button
				href="https://docs.panoramax.fr/pictures-metadata/quality_score/"
				target="_blank"
				kind="outline"
				style="width: 100%"
			>${this._t?.pnx.qualityscore_doc_link}</pnx-link-button>
		`;
	}
}

customElements.define("pnx-quality-score-doc", QualityScoreDoc);
