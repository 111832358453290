import { LitElement, css, html } from "lit";
import "./ButtonGroup.css";

/**
 * Button Group element allows to merge many button in a single bar.
 * @class Panoramax.components.ui.ButtonGroup
 * @element pnx-button-group
 * @extends [lit.LitElement](https://lit.dev/docs/api/LitElement/)
 * @example
 * ```html
 * <pnx-button-group id="pnx-widget-player" dir="row" size="xl" class="pnx-print-hidden">
 *   <pnx-button>B1</pnx-button>
 *   <pnx-button>B2</pnx-button>
 * </pnx-button-group>
 * ```
 */
export default class ButtonGroup extends LitElement {
	/** @private */
	static styles = css`
		div {
			display: flex;
			flex-wrap: nowrap;
			align-items: stretch;
			align-content: stretch;
			justify-content: center;
		}

		div.row { flex-direction: row; }
		div.column { flex-direction: column; }

		div.xl { line-height: 38px; font-size: 20px; }
		div.row.xl { height: 38px; }
		div.column.xl { width: 38px;}

		div.row ::slotted(*) { height: 100%; }
		div.column ::slotted(*) { width: 100%; }

		div.row.xl ::slotted(*) { min-width: 38px; }
		div.column.xl ::slotted(*) { min-height: 38px; }
	`;

	/**
     * Component properties.
	 * @memberof Panoramax.components.ui.ButtonGroup#
     * @type {Object}
     * @property {string} [dir] Group direction (row, column)
     * @property {string} [size] Group size (md, xl)
     */
	static properties = {
		dir: {type: String},
		size: {type: String},
	};

	constructor() {
		super();
		this.dir = "row";
		this.size = "md";
	}

	/** @private */
	render() {
		return html`<div class="${this.dir} ${this.size}">
			<slot></slot>
		</div>`;
	}
}

customElements.define("pnx-button-group", ButtonGroup);
