import { LitElement, html, css } from "lit";
import SwitchBig from "../../img/switch_big.svg";
import SwitchMini from "../../img/switch_mini.svg";

/**
 * Mini layout allows to show a reduced or collapsed component in a corner of a main component.
 * For example, show a reduced map on top of a photo display.
 * @class Panoramax.components.layout.Mini
 * @element pnx-mini
 * @slot `default` The component to display.
 * @extends [lit.LitElement](https://lit.dev/docs/api/LitElement/)
 * @fires Panoramax.components.layout.Mini#expand
 * @example
 * ```html
 * <pnx-mini icon=${fa(faMap)} ._parent=${viewer}>
 *   <pnx-map />
 * </pnx-mini>
 * ```
 */
export default class Mini extends LitElement {
	/** @private */
	static styles = css`
		:host {
			display: block;
			position: absolute;
			inset: 0;
			pointer-events: none;
		}

		slot {
			z-index: 120;
			pointer-events: auto;
		}

		pnx-button {
			z-index: 121;
			position: absolute;
			pointer-events: auto;
		}

		pnx-button img {
			height: 20px;
		}

		pnx-button:not([size="xxl"])::part(btn) {
			border-top-left-radius: 0;
			border-bottom-right-radius: 0;
		}
	`;

	/**
	 * Component properties.
	 * @memberof Panoramax.components.layout.Mini#
	 * @type {Object}
	 * @property {string} [icon] The icon to use in collapsed button.
	 * @property {boolean} [collapsed=false] Collapsed displays only a simple button.
	 */
	static properties = {
		icon: {type: String},
		collapsed: {type: Boolean, reflect: true},
	};

	constructor() {
		super();

		this.collapsed = false;
		this.icon = null;
	}

	/** @private */
	render() {
		const isSmall = this._parent?.isWidthSmall() || this._parent?.isHeightSmall();

		/**
		 * Event when user ask for component expand
		 * @event Panoramax.components.layout.Mini#expand
		 * @type {Event}
		 */
		const onExpand = () => this.dispatchEvent(new Event("expand", {bubbles: true, composed: true}));
		
		if(this.collapsed || isSmall) {
			const click = isSmall ? onExpand : () => this.collapsed = false;
			return html`<pnx-button
				kind="flat"
				size="xxl"
				style="bottom: 0"
				@click=${click}
			>${this.icon}</pnx-button>`;
		}
		else {
			return html`
				<slot></slot>

				<pnx-button
					kind="superflat"
					title=${this._parent?._t.pnx.expand_info}
					style="top: 0; right: 0"
					@click=${onExpand}
				>
					<img src=${SwitchBig} alt="" />
					${this._parent?._t.pnx.expand}
				</pnx-button>

				<pnx-button
					kind="flat"
					title=${this._parent?._t.pnx.minimize}
					style="bottom: 0; left: 0"
					@click=${() => this.collapsed = true}
				>
					<img src=${SwitchMini} alt="" />
				</pnx-button>
			`;
		}
	}
}

customElements.define("pnx-mini", Mini);
