import { LitElement, html, css, nothing } from "lit";
import { faSvg, titles, select, textarea, input } from "../styles";
import { fa } from "../../utils/widgets";
import { faAt } from "@fortawesome/free-solid-svg-icons/faAt";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons/faTriangleExclamation";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons/faCircleInfo";
import { faCommentDots } from "@fortawesome/free-solid-svg-icons/faCommentDots";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons/faPaperPlane";
import { getUserAccount } from "../../utils/utils";

const REPORT_NATURE = [
	"", "blur_missing", "blur_excess", "inappropriate", "privacy",
	"picture_low_quality", "mislocated", "copyright", "other"
];

/**
 * Report Form displays a form to send issues about pictures.
 * @class Panoramax.components.menus.ReportForm
 * @element pnx-report-form
 * @extends [lit.LitElement](https://lit.dev/docs/api/LitElement/)
 * @example
 * ```html
 * <pnx-report-form ._parent=${viewer} />
 * ```
 */
export default class ReportForm extends LitElement {
	/** @private */
	static styles = [ titles, faSvg, select, textarea, input, css`
		.group {
			margin-bottom: 10px;
		}

		.group:not(.group-inline) label {
			margin-bottom: 5px;
			display: inline-block;
		}
	` ];

	/** @private */
	static properties = {
		mode: {state: true},
		failure: {state: true},
	};

	/** @private */
	_onSubmit(e) {
		e.preventDefault();

		const picMeta = this._parent?.psv?.getPictureMetadata();
		const data = Object.fromEntries(new FormData(e.target));
		const params = {
			issue: data.issue,
			picture_id: data["whole-sequence"] ? null : picMeta?.id,
			reporter_comments: data.comments,
			reporter_email: data.email,
			sequence_id: picMeta?.sequence?.id
		};

		this.mode = "wait";

		// Call API
		this._parent?.api.sendReport(params).then(() => {
			this.mode = "success";
		}).catch(e => {
			console.error(e);
			this.mode = "failure";
			this.failure = e;
		});
	}

	/** @private */
	render() {
		const userAccount = getUserAccount();

		if(this.mode) {
			return html`
				<h4>${fa(faTriangleExclamation)} ${this._parent?._t.pnx.report}</h4>
				<p>
					${this.mode === "wait" ? this._parent?._t.pnx.report_wait : nothing}
					${this.mode === "success" ? this._parent?._t.pnx.report_success : nothing}
					${this.mode === "failure" ? this._parent?._t.pnx.report_failure.replace("{e}", this.failure) : nothing}
				</p>
			`;
		}

		return html`
			<h4>${fa(faTriangleExclamation)} ${this._parent?._t.pnx.report}</h4>

			${userAccount && this._parent?._t ? html`
				<p>${this._parent?._t.pnx.report_auth.replace("{a}", userAccount.name)}</p>
			` : nothing}

			<form @submit=${this._onSubmit.bind(this)}>
				<div class="group">
					<label for="issue">${fa(faCircleInfo)} ${this._parent?._t.pnx.report_nature_label}</label>
					<select name="issue" id="issue" required class="pnx-100w">
						${REPORT_NATURE.map(nature => html`
							<option
								value=${nature}
								${nature === "" ? html`disabled selected hidden` : nothing}
							>
								${this._parent?._t.pnx.report_nature[nature]}
							</option>
						`)}
					</select>
				</div>

				<div class="group group-inline">
					<input name="whole-sequence" id="whole-sequence" type="checkbox" />
					<label for="whole-sequence">${this._parent?._t.pnx.report_whole_sequence}</label>
				</div>

				<div class="group">
					<label for="comments">${fa(faCommentDots)} ${this._parent?._t.pnx.report_details}</label>
					<textarea name="comments" id="comments" placeholder=${this._parent?._t.pnx.report_details_placeholder}></textarea>
				</div>

				${!userAccount ? html`
					<div class="group">
						<label for="email">${fa(faAt)} ${this._parent?._t.pnx.report_email}</label>
						<input type="email" name="email" id="email" placeholder=${this._parent?._t.pnx.report_email_placeholder} class="pnx-100w" />
					</div>
				`: nothing}

				<pnx-button type="submit" style="width: 100%">
					${fa(faPaperPlane)} ${this._parent?._t.pnx.report_submit}
				</pnx-button>
			</form>
		`;
	}
}

customElements.define("pnx-report-form", ReportForm);
