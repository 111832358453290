import { LitElement, html, css, nothing } from "lit";
import { fa } from "../../utils/widgets";
import { faSvg, titles, select } from "../styles";
import { COLORS } from "../../utils/utils";
import { faEarthEurope } from "@fortawesome/free-solid-svg-icons/faEarthEurope";
import { faPalette } from "@fortawesome/free-solid-svg-icons/faPalette";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons/faInfoCircle";

/**
 * Map Layers menu allows user to select background and map theme.
 * @class Panoramax.components.menus.MapLayers
 * @element pnx-map-layers-menu
 * @extends [lit.LitElement](https://lit.dev/docs/api/LitElement/)
 * @example
 * ```html
 * <pnx-map-layers-menu _parent=${viewer} />
 * ```
 */
export default class MapLayers extends LitElement {
	/** @private */
	static styles = [ faSvg, titles, select, css`
		.legend {
			display: flex;
			flex-wrap: wrap;
			margin-top: 5px;
			justify-content: space-evenly;
		}

		.legend-entry {
			margin: 10px 8px 5px 0px;
			line-height: 12px;
			display: flex;
			align-items: center;
			font-size: 1.0em;
		}

		.legend-color {
			display: inline-block;
			width: 15px;
			height: 15px;
			border-radius: 3px;
			margin-right: 5px;
		}

		.legend-score {
			justify-content: center;
			gap: 0;
		}
	` ];

	/** @private */
	static properties = {
		theme: {state: true},
	};

	/** @private */
	connectedCallback() {
		super.connectedCallback();
		this._parent?.onceMapReady?.().then(() => {
			this.theme = this._parent.map._mapFilters.theme;
			this._parent.map.on("filters-changed", e => this.theme = e.theme);
		});
	}

	/** @private */
	_onThemeSelect(e) {
		this.theme = e.target.value;
		this._parent?._onMapFiltersChange();
	}

	/** @private */
	render() {
		const parts = [];

		// Background selector
		if(this._parent?.map?.hasTwoBackgrounds?.()) {
			parts.push(html`
				<h4>${fa(faEarthEurope)} ${this._parent?._t.pnx.map_background}</h4>
				<pnx-map-background ._parent=${this._parent}></pnx-map-background>
			`);
		}

		// Map theme selector
		parts.push(html`
			<h4>${fa(faPalette)} ${this._parent?._t.pnx.map_theme}</h4>
			<select id="pnx-map-theme" style="width: 100%;" @change=${this._onThemeSelect}>
				<option value="default" .selected=${this.theme == "default"}>${this._parent?._t.pnx.map_theme_default}</option>
				<option value="age" .selected=${this.theme == "age"}>${this._parent?._t.pnx.map_theme_age}</option>
				<option value="type" .selected=${this.theme == "type"}>${this._parent?._t.pnx.map_theme_type}</option>
				${this._parent?.map?._hasQualityScore?.() && html`<option value="score" .selected=${this.theme == "score"}>${this._parent?._t.pnx.map_theme_score}</option>`}
			</select>
		`);

		// Map legend
		parts.push(html`<div class="legend">
			${this.theme == "age" ? html`
				<div>
					<div class="legend-entry">
						<span class="legend-color" style="background-color: ${COLORS["PALETTE_4"]}"></span>
						${this._parent?._t.pnx["map_theme_age_4"]}
					</div>
					<div class="legend-entry">
						<span class="legend-color" style="background-color: ${COLORS["PALETTE_3"]}"></span>
						${this._parent?._t.pnx["map_theme_age_3"]}
					</div>
				</div>
				<div>
					<div class="legend-entry">
						<span class="legend-color" style="background-color: ${COLORS["PALETTE_2"]}"></span>
						${this._parent?._t.pnx["map_theme_age_2"]}
					</div>
					<div class="legend-entry">
						<span class="legend-color" style="background-color: ${COLORS["PALETTE_1"]}"></span>
						${this._parent?._t.pnx["map_theme_age_1"]}
					</div>
				</div>` : nothing}
			
			${this.theme == "type" ? html`
			 	<div class="legend-entry">
			 		<span class="legend-color" style="background-color: ${COLORS.QUALI_1}"></span>
			 		${this._parent?._t.pnx.picture_360}
			 	</div>
			 	<div class="legend-entry">
			 		<span class="legend-color" style="background-color: ${COLORS.QUALI_2}"></span>
			 		${this._parent?._t.pnx.picture_flat}
			 	</div>` : nothing}
			
			${this.theme == "score" ? html`<div>
				<pnx-quality-score></pnx-quality-score>
				<pnx-button
					title="${this._parent?._t.pnx.metadata_quality_help}"
					kind="outline"
					@click=${() => this._parent?._showQualityScoreDoc()}
				>
					${fa(faInfoCircle)}
				</pnx-button></div>` : nothing}
		</div>`);

		return parts;
	}
}

customElements.define("pnx-map-layers-menu", MapLayers);
