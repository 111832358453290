import { LitElement, html, css } from "lit";

/**
 * CorneredGrid layout offers a convenient way to display overlay widgets on top of a background component.
 * This is useful for showing buttons in corners over a map or picture display.
 * Grid layout is organised through many slots (background and corners) : two rows (top, bottom) and three columns (right, middle, left).
 * @class Panoramax.components.layout.CorneredGrid
 * @element pnx-cornered-grid
 * @extends [lit.LitElement](https://lit.dev/docs/api/LitElement/)
 * @slot `bg` The full size background component
 * @slot `top-left` The top-left corner
 * @slot `top` The top middle corner
 * @slot `top-right` The top-right corner
 * @slot `bottom-left` The bottom-left corner
 * @slot `bottom` The bottom middle corner
 * @slot `bottom-right` The bottom-right corner
 * @example
 * ```html
 * <pnx-cornered-grid>
 *   <pnx-map slot="bg" />
 *   <pnx-button slot="top-left" />
 *   <pnx-button slot="top-left" />
 *   <pnx-legend slot="bottom" />
 * </pnx-cornered-grid>
 * ```
 */
export default class CorneredGrid extends LitElement {
	/** @private */
	static styles = css`
		:host {
			position: relative;
			width: 100%;
			height: 100%;
			display: flex;
			flex-direction: column;
			gap: 5px;
			flex-wrap: no-wrap;
		}

		.bg {
			position: absolute;
			inset: 0;
			z-index: 0;
		}

		.row {
			display: flex;
			flex-direction: row;
			flex: 1 1 50%;
			pointer-events: none;
		}

		.corner {
			position: relative;
			flex: 1 1 33%;
			display: flex;
			gap: 10px;
		}

		.corner slot {
			pointer-events: auto;
			position: relative;
			z-index: 120;
		}

		.row.bottom .corner:not(.middle) { flex-direction: column; }
		.row.bottom .corner.left { flex-direction: column-reverse; justify-content: flex-start; align-items: flex-start; }
		.row.bottom .corner.middle { justify-content: center; align-items: flex-end; }
		.row.bottom .corner.right { justify-content: flex-end; align-items: flex-end; }

		.row.top .corner { flex-direction: row; }
		.row.top .corner.left { justify-content: flex-start; align-items: flex-start; }
		.row.top .corner.middle { justify-content: center; }
		.row.top .corner.right { justify-content: flex-end; align-items: flex-start; }
	`;

	/** @private */
	render() {
		return html`
			<div class="bg">
				<slot name="bg"></slot>
			</div>
			<div class="row top">
				<div class="corner left" part="corner-top-left">
					<slot name="top-left"></slot>
				</div>
				<div class="corner middle" part="corner-top">
					<slot name="top"></slot>
				</div>
				<div class="corner right" part="corner-top-right">
					<slot name="top-right"></slot>
				</div>
			</div>
			<div class="row bottom">
				<div class="corner left" part="corner-bottom-left">
					<slot name="bottom-left"></slot>
				</div>
				<div class="corner middle" part="corner-bottom">
					<slot name="bottom"></slot>
				</div>
				<div class="corner right" part="corner-bottom-right">
					<slot name="bottom-right"></slot>
				</div>
			</div>
		`;
	}
}

customElements.define("pnx-cornered-grid", CorneredGrid);
