import { LitElement, html, nothing } from "lit";
import { fa } from "../../../utils/widgets";
import { faBackward } from "@fortawesome/free-solid-svg-icons/faBackward";
import { faPlay } from "@fortawesome/free-solid-svg-icons/faPlay";
import { faPause } from "@fortawesome/free-solid-svg-icons/faPause";
import { faForward } from "@fortawesome/free-solid-svg-icons/faForward";
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons/faEllipsisVertical";
import "./Player.css";

/**
 * Player widget shows prev/play/pause/next/more buttons in a unified toolbar.
 * @class Panoramax.components.ui.widgets.Player
 * @element pnx-widget-player
 * @extends [lit.LitElement](https://lit.dev/docs/api/LitElement/)
 * @example
 * ```html
 * <pnx-widget-player _parent=${viewer} />
 * ```
 */
export default class Player extends LitElement {
	/**
	 * Component properties.
	 * @memberof Panoramax.components.ui.widgets.Player#
	 * @type {Object}
	 * @property {boolean} [playing=false] Is sequence currently playing ?
	 */
	static properties = {
		playing: {type: Boolean, reflect: true},
		_activePrev: {state: true},
		_activePlay: {state: true},
		_activeNext: {state: true},
	};

	constructor() {
		super();

		this.playing = false;
		this._activePrev = true;
		this._activePlay = true;
		this._activeNext = true;
	}

	/** @private */
	createRenderRoot() {
		return this;
	}

	/** @private */
	connectedCallback() {
		super.connectedCallback();

		this._parent?.oncePSVReady?.().then(() => {
			this._parent.psv.addEventListener("sequence-playing", () => this.playing = true);
			this._parent.psv.addEventListener("sequence-stopped", () => this.playing = false);
			this._parent.psv.addEventListener("picture-loaded", this._changeActiveStates.bind(this));
			this._parent.addEventListener("pictures-navigation-changed", this._changeActiveStates.bind(this));
			this._changeActiveStates();
		});
	}

	/** @private */
	_changeActiveStates() {
		if(this._parent.psv.getPicturesNavigation?.() === "pic") {
			this._activeNext = false;
			this._activePlay = false;
			this._activePrev = false;
		}
		else {
			this._activePrev = this._parent.psv.getPictureMetadata()?.sequence?.prevPic != null;

			if(this._parent.psv.getPictureMetadata()?.sequence?.nextPic != null) {
				this._activeNext = true;
				this._activePlay = true;
			}
			else {
				this._activeNext = false;
				this._activePlay = false;
			}
		}
		this.requestUpdate();
	}

	/** @private */
	_onPlayClick() {
		if(this._parent?.psv?.isSequencePlaying()) {
			this.playing = false;
			this._parent?.psv?.stopSequence();
		}
		else {
			this.playing = true;
			this._parent?.psv?.playSequence();
		}
	}

	/** @private */
	render() {
		if(!this._parent?.psv?.addEventListener) { return nothing; }

		return html`
			<pnx-button-group
				id="pnx-widget-player"
				dir="row"
				size="xl"
				class="pnx-print-hidden"
			>
				<pnx-button
					kind="superflat"
					id="pnx-player-prev"
					title=${this._parent?._t.pnx.sequence_prev}
					@click=${() => this._parent?.psv?.goToPrevPicture()}
					.disabled=${!this._activePrev}
				>${fa(faBackward)}</pnx-button>

				<pnx-button
					kind="superflat"
					id="pnx-player-play"
					title=${this.playing ? this._parent?._t.pnx.sequence_pause : this._parent?._t.pnx.sequence_play}
					.disabled=${!this._activePlay}
					@click=${this._onPlayClick.bind(this)}
				>${this.playing ? fa(faPause) : fa(faPlay)}</pnx-button>

				<pnx-button
					kind="superflat"
					id="pnx-player-next"
					title=${this._parent?._t.pnx.sequence_next}
					.disabled=${!this._activeNext}
					@click=${() => this._parent?.psv?.goToNextPicture()}
				>${fa(faForward)}</pnx-button>

				${!this._parent?.isWidthSmall() ? html`
					<pnx-togglable-group padded="false" ._parent=${this._parent}>
						<pnx-button
							kind="flat"
							id="pnx-player-more"
							title=${this._parent?._t.pnx.sequence_more}
							class="pnx-xs-hidden"
							slot="button"
						>${fa(faEllipsisVertical)}</pnx-button>

						<pnx-player-options ._parent=${this._parent}></pnx-player-options>
					</pnx-togglable-group>
				` : nothing}
			</pnx-button-group>
		`;
	}
}

customElements.define("pnx-widget-player", Player);
