import { LitElement, html } from "lit";
import { fa } from "../../../utils/widgets";
import { faShareNodes } from "@fortawesome/free-solid-svg-icons/faShareNodes";

/**
 * Share button handles showing/hiding share menu.
 * @class Panoramax.components.ui.widgets.Share
 * @element pnx-widget-share
 * @extends [lit.LitElement](https://lit.dev/docs/api/LitElement/)
 * @example
 * ```html
 * <pnx-widget-share _parent=${viewer} />
 * ```
 */
export default class Share extends LitElement {
	/** @private */
	render() {
		return html`<pnx-togglable-group ._parent=${this._parent}>
			<pnx-button
				slot="button"
				kind="flat"
				size="xxl"
				title=${this._parent?._t.pnx.share}
			>${fa(faShareNodes)}</pnx-button>
			<pnx-share-menu ._parent=${this._parent}></pnx-share-menu>
		</pnx-togglable-group>`;
	}
}

customElements.define("pnx-widget-share", Share);
