import { css } from "lit";

/* eslint-disable import/no-unused-modules */

// Classic panel
export const panel = css`
	.pnx-panel {
		position: absolute;
		transition: opacity 0.2s 0.1s;
		border-radius: 25px;
		border: 1px solid var(--widget-border-div);
		background-color: var(--widget-bg);
		color: var(--widget-font);
		min-width: 250px;
		box-sizing: border-box;
		font-family: var(--font-family);
	}

	.pnx-panel.pnx-hidden {
		opacity: 0;
		display: block !important;
		pointer-events: none;
	}

	.pnx-panel.pnx-padded {
		padding: 15px;
	}
`;

// Font Awesome SVG
export const faSvg = css`
	.svg-inline--fa {
		display: var(--fa-display, inline-block);
		height: 1em;
		overflow: visible;
		vertical-align: -0.125em;
	}
	svg:not(:root).svg-inline--fa, svg:not(:host).svg-inline--fa {
		overflow: visible;
		box-sizing: content-box;
	}
	@keyframes fa-spin {
		from {transform:rotate(0deg);}
		to {transform:rotate(360deg);}
	}
	.fa-spin {
		animation: fa-spin 2s infinite linear;
	}
`;

// Textarea
export const textarea = css`
	textarea {
		background-color: var(--widget-bg);
		color: var(--widget-font);
		border: 1px solid var(--widget-border-div);
		border-radius: 10px;
		padding: 5px;
		width: 100%;
		box-sizing: border-box;
		font-family: var(--font-family);
	}
`;

// Select
export const select = css`
	select {
		background-color: var(--widget-bg);
		color: var(--widget-font);
		border: 1px solid var(--widget-border-div);
		border-radius: 20px;
		font-size: 16px;
		padding: 2px 10px;
		font-family: var(--font-family);
	}

	select.pnx-100w {
		width: 100%;
		box-sizing: border-box;
	}
`;

// Input
export const input = css`
	input {
		background-color: var(--widget-bg);
		color: var(--widget-font);
		border: 1px solid var(--widget-border-div);
		border-radius: 20px;
		font-size: 16px;
		padding: 2px 10px;
		font-family: var(--font-family);
	}

	input.pnx-100w {
		width: 100%;
		box-sizing: border-box;
	}
`;

// Buttons
export const btn = css`
	:host {
		display: inline-flex;
	}
	
	/* Common properties */
	.pnx-btn {
		white-space: nowrap;
		font-size: 1em;
		font-family: var(--font-family);
		flex-grow: 1;
		flex-shrink: 1;
		text-align: center;
		padding: 5px;
		font-weight: 600;
		flex-basis: 30%;
		line-height: 18px;
		justify-content: center;
		border-radius: 8px;
		cursor: pointer;
		text-decoration: none;
		display: inline-flex;
		align-items: center;
		gap: 5px;
		position: relative;
		width: 100%;
		height: 100%;
	}

	.pnx-btn:disabled { color: var(--widget-bg-inactive); }

	.pnx-btn.pnx-btn-active,
	.pnx-btn.pnx-btn-active:hover,
	.pnx-btn.pnx-btn-full.pnx-btn-active:hover,
	.pnx-btn.pnx-btn-outline.pnx-btn-active:hover {
		background-color: var(--widget-bg-active);
		border-color: var(--widget-bg-active);
		color: var(--widget-font-active);
	}

	.pnx-btn .svg-inline--fa,
	.pnx-btn ::slotted(.svg-inline--fa) {
		height: 16px;
	}

	/* Sizing */
	.pnx-btn-l {
		border-radius: 12px;
		height: 24px;
		line-height: 24px;
		font-size: 20px;
		width: 24px;
	}

	.pnx-btn-xl {
		border-radius: 20px;
		height: 40px;
		line-height: 40px;
		font-size: 16px;
		font-weight: 500;
		min-width: 40px;
		padding: 0 10px;
		gap: 8px;
	}

	.pnx-btn.pnx-btn-xl .svg-inline--fa,
	.pnx-btn.pnx-btn-xl ::slotted(.svg-inline--fa) {
		height: 16px;
	}

	.pnx-btn-xxl {
		border-radius: 28px;
		height: 56px;
		line-height: 56px;
		font-size: 24px;
		width: 56px;
	}

	.pnx-btn.pnx-btn-xxl .svg-inline--fa,
	.pnx-btn.pnx-btn-xxl ::slotted(.svg-inline--fa) {
		height: 24px;
	}

	/* Fully-filled style */
	.pnx-btn-full {
		background-color: var(--widget-bg-primary);
		color: var(--widget-font-direct);
		border: none;
	}

	.pnx-btn-full:not(:disabled):hover {
		background-color: var(--widget-bg-primary-hover);
	}

	/* Outline style */
	.pnx-btn-outline {
		border: 1px solid var(--widget-border-btn);
		color: var(--widget-font-direct);
		background-color: var(--widget-bg);
	}

	.pnx-btn-outline:not(:disabled):hover {
		background-color: var(--widget-bg-hover);
	}

	/* Flat style */
	.pnx-btn-flat,
	.pnx-btn-flat.pnx-btn-active,
	.pnx-btn-flat.pnx-btn-active:hover,
	.pnx-btn-flat:not(:disabled):hover {
		border: 1px solid var(--widget-border-div);
		color: var(--widget-font);
		background-color: var(--widget-bg);
	}

	.pnx-btn-flat:not(:disabled):hover {
		background-color: var(--widget-bg-hover);
	}

	/* Superflat style */
	.pnx-btn-superflat,
	.pnx-btn-superflat.pnx-btn-active,
	.pnx-btn-superflat.pnx-btn-active:hover,
	.pnx-btn-superflat:not(:disabled):hover {
		border: 1px solid var(--widget-border-div);
		color: var(--widget-font-direct);
		background-color: var(--widget-bg);
	}

	.pnx-btn-superflat:not(:disabled):hover {
		background-color: var(--widget-bg-hover);
	}
`;

// Titles
export const titles = css`
	h1, h2, h3, h4, h5, h6 {
		font-family: var(--font-family);
	}
	
	h3 {
		font-size: 1.1em;
		line-height: 1.1em;
		font-weight: 500;
		margin: 10px 0 10px 0;
	}

	h4 {
		font-size: 1.0em;
		line-height: 1.0em;
		font-weight: 500;
		margin: 15px 0;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		gap: 5px;
	}

	h4:first-of-type { margin-top: 0; }

	h4 > svg.svg-inline--fa {
		height: 18px;
	}
`;

// Active icon badge
export const activeIcon = css`
	.pnx-active-icon {
		box-sizing: border-box;
		background-color: var(--orange);
		border: 3px solid #fff;
		border-radius: 8px;
		height: 15px;
		left: 20px;
		position: absolute;
		top: 5px;
		width: 15px;
	}
`;

// Placeholder background
export const placeholder = css`
	@keyframes animatedBackground {
		from {
			background-position: 0 0;
		}
		to {
			background-position: 1000px 0;
		}
	}

	.pnx-placeholder-loading {
		width: 100px;
		height: 100px;
		background-image: linear-gradient(
			90deg,
			#e4e4e4 0%,
			#f1f1f1 40%,
			#ededed 60%,
			#e4e4e4 100%
		);
		background-position: 0px 0px;
		background-repeat: repeat;
		animation: animatedBackground 5s linear infinite;
	}
`;

// Expandable block
export const expandable = css`
	details summary {
		font-size: 1.0em;
		line-height: 1.0em;
		font-weight: 500;
		margin: 15px 0;
		cursor: pointer;
	}

	details summary svg {
		height: 18px;
		vertical-align: sub;
		margin-right: 2px;
	}
`;

// Tables
export const tables = css`
	table {
		border-collapse: collapse;
		font-size: 0.9rem;
		width: 100%;
	}

	thead {
		background-color: var(--blue-pale);
	}

	th[scope="row"] {
		text-align: left;
	}

	th, td {
		border: 1px solid var(--grey-semi-dark);
		padding: 8px 10px;
		max-width: 600px;
	}

	.pnx-table-light th[scope="row"] {
		width: 30%;
	}

	.pnx-table-light th,
	.pnx-table-light td {
		border: none;
		padding: 5px 10px;
		text-align: left;
	}

	.pnx-table-light .pnx-td-with-id {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	table:not(.pnx-table-light) td:last-of-type {
		text-align: center;
	}

	table:not(.pnx-table-light) tbody > tr:nth-of-type(even) {
		background-color: var(--grey);
	}
`;