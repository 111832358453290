import { LitElement, html, nothing } from "lit";
import { fa } from "../../../utils/widgets";
import { faSliders } from "@fortawesome/free-solid-svg-icons/faSliders";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons/faChevronDown";
import { faXmark } from "@fortawesome/free-solid-svg-icons/faXmark";
import { activeIcon } from "../../styles";
import { MAP_FILTERS } from "../MapMore";

/**
 * Collapsible button showing off map filters menus.
 * @class Panoramax.components.ui.widgets.MapFiltersButton
 * @element pnx-widget-mapfilters
 * @extends [lit.LitElement](https://lit.dev/docs/api/LitElement/)
 * @example
 * ```html
 * <pnx-widget-mapfilters user-search="" _parent=${viewer} />
 * ```
 */
export default class MapFiltersButton extends LitElement {
	/** @private */
	static styles = [activeIcon];

	/**
	 * Component properties.
	 * @memberof Panoramax.components.ui.widgets.MapFiltersButton#
	 * @type {Object}
	 * @property {boolean} [user-search=false] Should user search filter show up ?
	 * @property {boolean} [quality-score=false] Should quality score filter show up ?
	 */
	static properties = {
		"user-search": {type: Boolean},
		"quality-score": {type: Boolean},
		_active: {state: true},
	};

	constructor() {
		super();
		this["user-search"] = false;
		this["quality-score"] = false;
		this._active = false;
	}

	/** @private */
	connectedCallback() {
		super.connectedCallback();

		// Listen to user visibility changes to switch the filter active icon
		this._parent?.onceMapReady?.().then(() => {
			this._active = (
				Object.keys(this._parent.map._mapFilters).filter(d => d && d !== "theme").length > 0
				|| this._parent.map.getVisibleUsers().filter(u => u !== "geovisio").length > 0
			);
			
			this._parent.map.on("filters-changed", e => {
				this._active = (
					Object.keys(e).filter(d => d && MAP_FILTERS.includes(d) && d != "theme").length > 0
					|| this._parent.map.getVisibleUsers().filter(u => u !== "geovisio").length > 0
				);
			});

			this._parent.map.on("users-changed", e => {
				this._active = (
					Object.keys(this._parent.map._mapFilters).filter(d => d && d !== "theme").length > 0
					|| e.usersIds.filter(u => u !== "geovisio").length > 0
				);
			});
		});
	}

	/** @private */
	render() {
		const isSmall = this._parent?.isWidthSmall();
		const resetIcon = fa(faXmark);
		resetIcon.addEventListener("click", e => {
			e.stopPropagation();
			this.shadowRoot.querySelector("pnx-map-filters-menu")._onReset();
			this.shadowRoot.querySelector("#pnx-widget-filter")._opened = false;
		});

		return html`
			<pnx-togglable-group
				id="pnx-widget-filter"
				padded="false"
				class="pnx-only-map pnx-print-hidden"
				._parent=${this._parent}
			>
				<pnx-button kind="flat" size="xl" slot="button">
					${fa(faSliders)}
					${this._active ? html`<span class="pnx-active-icon"></span>` : nothing}
					${isSmall ? nothing : this._parent?._t.pnx.filters}
					${isSmall ? nothing : (this._active ? resetIcon : fa(faChevronDown))}
				</pnx-button>
				<pnx-map-filters-menu
					id="pnx-map-filters-menu"
					._parent=${this._parent}
					user-search=${this["user-search"]}
					quality-score=${this["quality-score"]}
				></pnx-map-filters-menu>
			</pnx-togglable-group>
		`;
	}
}

customElements.define("pnx-widget-mapfilters", MapFiltersButton);
