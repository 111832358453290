import {LitElement, html, nothing, css} from "lit";

/**
 * Map legend displays information about map sources.
 * @class Panoramax.components.menus.MapLegend
 * @element pnx-map-legend
 * @extends [lit.LitElement](https://lit.dev/docs/api/LitElement/)
 * @example
 * ```html
 * <pnx-map-legend ._parent=${viewer} />
 * ```
 */
export default class MapLegend extends LitElement {
	/** @private */
	static styles = css`
		:host {
			font-size: 0.9em;
		}
		small {
			font-size: 1em;
		}
	`;

	/** @private */
	render() {
		const mapAttrib = this._parent?.map?._attribution?._innerContainer;

		return html`
			${mapAttrib && mapAttrib.innerHTML.length > 0 ? html`${this._parent?._t.map.map_data}<br />${mapAttrib}` : nothing}
		`;
	}
}

customElements.define("pnx-map-legend", MapLegend);
