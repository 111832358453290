import {LitElement, html, css} from "lit";
import { panel } from "../../styles";
import { fa } from "../../../utils/widgets";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons/faInfoCircle";
import PanoramaxImg from "../../../img/panoramax.svg";

/**
 * Legend widget, handling switch between map and photo components.
 * Also displays a default "About Panoramax" message.
 * @class Panoramax.components.ui.widgets.Legend
 * @element pnx-widget-legend
 * @extends [lit.LitElement](https://lit.dev/docs/api/LitElement/)
 * @example
 * ```html
 * <pnx-widget-legend
 *   _parent=${viewer}
 *   focus="map"
 *   picture="PICTURE-ID-IF-ANY"
 * />
 * ```
 */
export default class Legend extends LitElement {
	/** @private */
	static styles = [panel, css`
		.pnx-panel.pnx-padded {
			border-radius: 10px;
			padding: 10px;
			position: relative;
			width: 250px;
			max-width: 80vh;
			z-index: 120;
		}
		.presentation {
			font-size: 0.9em;
			display: flex;
			gap: 5px;
			align-items: center;
		}
		.logo {
			width: 45px;
		}
	`];

	/**
	 * Component properties.
	 * @memberof Panoramax.components.ui.widgets.Legend#
	 * @type {Object}
	 * @property {string} [focus] The focused main component (map, pic)
	 * @property {string} [picture] The picture ID
	 */
	static properties = {
		focus: {type: String},
		picture: {type: String},
	};

	render() {
		return html`<div class="pnx-panel pnx-padded" part="panel">
			<div
				class="presentation"
				style=${this.focus != "map" && this.picture ? "display: none": ""}
			>
				<img class="logo" src=${PanoramaxImg} alt="" />
				<div>
					Panoramax est le géocommun des photos de rues.
					<pnx-link-button title=${this._parent?._t.map.more_panoramax} kind="outline" href="https://panoramax.fr" target="_blank">
						${fa(faInfoCircle, { styles: {height: "12px", "margin-inline": "3px"}})}
					</pnx-link-button>
				</div>
			</div>
			<pnx-picture-legend
				._parent=${this._parent}
				style=${this.focus == "map" ? "display: none": ""}
			></pnx-picture-legend>
			<pnx-map-legend
				._parent=${this._parent}
				style=${this.focus != "map" ? "display: none": ""}
			></pnx-map-legend>
		</div>`;
	}
}

customElements.define("pnx-widget-legend", Legend);
