import { LitElement, html, css } from "lit";
import BackgroundAerial from "../../img/bg_aerial.jpg";
import BackgroundStreets from "../../img/bg_streets.jpg";

/**
 * Map Background menu allows user to select background.
 * @class Panoramax.components.menus.MapBackground
 * @element pnx-map-background
 * @extends [lit.LitElement](https://lit.dev/docs/api/LitElement/)
 * @example
 * ```html
 * <pnx-map-background size="sm" _parent=${viewer} />
 * ```
 */
export default class MapBackground extends LitElement {
	/** @private */
	static styles = [ css`
		:host {
			justify-content: space-evenly;
			box-sizing: border-box;
			display: flex;
			gap: 10px;
		}
		input { display: none; }
		label { cursor: pointer; }

		img {
			width: 40px;
			border-radius: 5px;
			vertical-align: middle;
			margin-right: 5px;
			border: 2px solid var(--widget-bg);
		}

		img.sm {
			width: 32px;
		}

		input:checked + label img {
			outline: 3px solid var(--widget-border-btn);
		}
	` ];

	/**
     * Component properties.
	 * @memberof Panoramax.components.menus.MapBackground#
     * @type {Object}
     * @property {string} [size=md] The size of the button (sm, md)
     */
	static properties = {
		bg: {state: true},
		size: {type: String},
	};

	constructor() {
		super();
		this.size = "md";
	}

	/** @private */
	connectedCallback() {
		super.connectedCallback();
		this._parent?.map?.on("background-changed", e => {
			this.bg = e.background;
		});
		this.bg = this._parent?.map?.getBackground();
	}

	/** @private */
	_onBgSelect(e) {
		this.bg = e.target.value;
		this._parent?.map?.setBackground(this.bg);
	}

	/** @private */
	render() {
		return html`
			<input
				type="radio"
				id="bg-streets"
				name="bg"
				value="streets"
				@change=${this._onBgSelect}
				.checked=${this.bg == "streets"}
			/>
			<label for="bg-streets">
				<img src=${BackgroundStreets} class=${this.size} alt="" />
				${this._parent?._t.pnx.map_background_streets}
			</label>
			<input
				type="radio"
				id="bg-aerial"
				name="bg"
				value="aerial"
				@change=${this._onBgSelect}
				.checked=${this.bg == "aerial"}
			/>
			<label for="bg-aerial">
				<img src=${BackgroundAerial} class=${this.size} alt="" />
				${this._parent?._t.pnx.map_background_aerial}
			</label>
		`;
	}
}

customElements.define("pnx-map-background", MapBackground);
