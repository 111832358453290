// Every single icon imported separately to reduce bundle size
import { icon } from "@fortawesome/fontawesome-svg-core";


/**
 * Transform Font Awesome icon definition into HTML element
 * @param {IconDefinition} i The icon to use
 * @param {object} [o] [FontAwesome icon parameters](https://origin.fontawesome.com/docs/apis/javascript/methods#icon-icondefinition-params)
 * @returns {Element} HTML element
 * @private
 */
export function fa(i, o) {
	return icon(i, o).node[0];
}

/**
 * Table cell with a copy link
 * @private
 */
export function createLinkCell(id, url, title, _t) {
	const link = document.createElement("a");
	link.href = url;
	link.target = "_blank";
	link.title = title;
	link.textContent = id;

	const buttonContainer = createWebComp("pnx-copy-button", {text: id, _t});
	return [link, buttonContainer];
}

/**
 * Create a light table
 * @private 
 */
export function createTable(className, rows) {
	const table = document.createElement("table");
	table.className = className;

	rows.forEach(({ section, value, values, classes }) => {
		const tr = document.createElement("tr");
		const th = document.createElement("th");
		th.scope = "row";
		th.textContent = section;
		tr.appendChild(th);

		const td = document.createElement("td");
		if(classes) { td.classList.add(...classes); }
		if(values) { values.forEach(v => td.appendChild(v)); }
		else if(value instanceof HTMLElement) { td.appendChild(value); }
		else { td.innerHTML = value; }
		tr.appendChild(td);

		table.appendChild(tr);
	});

	return table;
}

/**
 * Create a web component with its initial properties
 * @private
 */
export function createWebComp(tag, props = {}) {
	const wc = document.createElement(tag);
	Object.entries(props).forEach(([k,v]) => {
		if(k.startsWith("_")) { wc[k] = v; }
		else if(k.startsWith("fn")) { wc[k.substring(2)] = v; }
		else if(k.startsWith("on")) { wc.addEventListener(k.substring(2), v); }
		else if(v) { wc.setAttribute(k, v); }
	});
	return wc;
}

/**
 * Listen to parent events that may lead to a menu closure
 * @private
 */
export function listenForMenuClosure(me, callback) {
	// Other menu opened
	me._parent?.addEventListener("menu-opened", e => {
		if(e.detail.menu != me) { callback(); }
	});

	// Map click
	me._parent?.onceMapReady?.().then(() => {
		me._parent.map?.on?.("click", () => callback());
	});

	// Photo click
	me._parent?.oncePSVReady?.().then(() => {
		me._parent.psv.addEventListener("click", () => callback());
	});
}
