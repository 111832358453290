import { LitElement, css } from "lit";
import { btn } from "../styles";

/**
 * Link Button element displays a HTML `<a>` anchor with a button style.
 * @class Panoramax.components.ui.LinkButton
 * @element pnx-link-button
 * @extends [lit.LitElement](https://lit.dev/docs/api/LitElement/)
 * @example
 * ```html
 * <pnx-link-button
 *   title="Redirect to other website"
 *   kind="outline"
 *   href="https://panoramax.fr"
 *   target="_blank"
 * >
 *   Click to go on Panoramax
 * </pnx-link-button>
 * ```
 */
export default class LinkButton extends LitElement {
	/** @private */
	static styles = [ btn, css`
		.pnx-btn {
			padding: 5px 0;
		}
	` ];

	/**
	 * Component properties.
	 * @memberof Panoramax.components.ui.LinkButton#
	 * @type {Object}
	 * @property {string} [href] The URL the button should navigate to when clicked
	 * @property {string} [target] Specifies where to open the linked document (e.g., '_blank')
	 * @property {string} [title] Tooltip text displayed when hovering over the button
	 * @property {string} [download] Indicates if the linked resource should be downloaded
	 * @property {string} [class] Custom CSS class for additional styling
	 * @property {string} [kind=full] The style variation of the button (full, outline, flat, superflat)
	 */
	static properties = {
		href: { type: String },
		target: { type: String },
		title: { type: String },
		download: { type: String },
		class: { type: String },
		kind: {type: String},
	};

	constructor() {
		super();
		this.kind = "full";
	}

	/** @private */
	render() {
		const a = document.createElement("a");
		a.setAttribute("part", "btn");
		this.constructor.observedAttributes
			.filter(k => this[k] !== undefined)
			.forEach(k => a.setAttribute(k, this[k]));
		a.classList.add("pnx-btn", `pnx-btn-${this.kind}`);
		const slot = document.createElement("slot");
		a.appendChild(slot);
		return a;
	}
}

customElements.define("pnx-link-button", LinkButton);
