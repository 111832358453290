import { LitElement } from "lit";
import { btn } from "../styles";

/**
 * Button element displays a direct action button.
 * Binds to a HTML button, so you can use any events offered by it.
 * @class Panoramax.components.ui.Button
 * @element pnx-button
 * @extends [lit.LitElement](https://lit.dev/docs/api/LitElement/)
 * @example
 * ```html
 * <pnx-button kind="flat" size="l" @click=${() => alert("click!")}>
 *    Click on me
 * </pnx-button>
 * ```
 */
export default class Button extends LitElement {
	/** @private */
	static styles = btn;

	/**
	 * Component properties.
	 * @memberof Panoramax.components.ui.Button#
	 * @type {Object}
	 * @property {boolean} [active=false] Whether the button is in an active state.
	 * @property {string} [kind=full] The style variation of the button (full, outline, flat, superflat)
	 * @property {string} [size=md] The size of the button (md, l, xl, xxl)
	 * @property {boolean} [disabled=false] Whether the button is disabled.
	 * @property {string} [type] The button type (e.g., 'submit').
	 */
	static properties = {
		active: {type: Boolean},
		kind: {type: String},
		size: {type: String},
		disabled: {type: Boolean},
		type: {type: String},
	};

	constructor() {
		super();
		this.active = false;
		this.disabled = false;
		this.kind = "full";
		this.size = "md";
	}

	/** @private */
	connectedCallback() {
		super.connectedCallback();

		if(this.type === "submit") {
			this.addEventListener("click", () => this.closest("FORM")?.requestSubmit());
		}
	}

	/** @private */
	render() {
		const b = document.createElement("button");
		b.setAttribute("part", "btn");

		this.constructor.observedAttributes
			.filter(k => this[k] !== undefined && this[k] !== false && !["active", "kind", "size"].includes(k))
			.forEach(k => b.setAttribute(k, this[k]));

		b.classList.add("pnx-btn", `pnx-btn-${this.kind}`, `pnx-btn-${this.size}`);
		if(this.active) { b.classList.add("pnx-btn-active"); }

		const slot = document.createElement("slot");
		b.appendChild(slot);

		return b;
	}
}

customElements.define("pnx-button", Button);
